import resume from '../assets/pdf/resume.pdf'
import profileImg from '../assets/png/profileImg.png'

export const headerData = {
    name: "Adam Green",
    title: "Data Scientist, IBM",
    desciption: "I collaborate with clients to rapidly design and develop innovative data science solutions, leveraging the latest IBM and open-source technologies, data science techniques and machine learning models.",
    image: profileImg,
    resumePdf: resume
}
