export const educationData = [
    {
        id: 1,
        institution: 'Birkbeck, University of London',
        course: 'MSc Applied Statistics',
        startYear: '2019',
        endYear: '2021'
    },
    {
        id: 2,
        institution: 'Cardiff University',
        course: 'BSc Mathematics, Operational Research and Statistics',
        startYear: '2014',
        endYear: '2018'
    }
]