export const experienceData = [
    {
        id: 1,
        company: 'IBM Client Engineering',
        jobtitle: 'Data Scientist',
        startYear: '2021',
        endYear: 'Current'
    },
    {
        id: 2,
        company: 'Capgemini Invent',
        jobtitle: 'Associate Consultant / Consultant',
        startYear: '2018',
        endYear: '2020'
    },
    {
        id: 3,
        company: 'HM Revenue & Customs',
        jobtitle: 'Operational Research Analyst',
        startYear: '2016',
        endYear: '2017'
    },
]